import {
	FFmpeg
} from "@ffmpeg/ffmpeg"
import {
	fetchFile
} from "@ffmpeg/util";

const convertorWebmToMp3 = async (webmBlob) => {
	const ffmpeg = new FFmpeg();
	await ffmpeg.load();

	const inputName = 'input.webm';
	const outputName = 'output.mp3';

	await ffmpeg.writeFile(inputName, await fetchFile(webmBlob));
	await ffmpeg.exec(["-i", inputName, outputName]);
	const fileData = await ffmpeg.readFile(outputName);
	return new Blob([fileData.buffer], {
		type: 'audio/mp3',
	})
};
const convertorBase64ToMp3 = async (base64) => {};
const playMp3FromBase64 = async (base64) => {
	const _audio = new Audio(['data:audio/mp3;base64,',base64].join(' '));
	await _audio.load();
	await _audio.play();
};

export {
	playMp3FromBase64,

	convertorWebmToMp3,
	convertorBase64ToMp3
}
